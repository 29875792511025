// 费用待摊
// 对公付款单
export const PAY_APPLY_ITEM_VIEW_ROUTE = {
    name: 'payApplyItemView',
    path: 'absorption/cost/pay',
    meta: { name: '对公付款单', keepAlive: true, uid: 'payApplyItemView' },
    component: () =>
        import ('@/views/absorption/cost/pay')
}

// 费用待摊单
export const COST_ABSORPTION_WAIT_VIEW_ROUTE = {
    name: 'costAbsorptionWaitView',
    path: 'absorption/cost/wait',
    meta: { name: '费用待摊单', keepAlive: true, uid: 'costAbsorptionWaitView' },
    component: () =>
        import ('@/views/absorption/cost/wait')
}

export const COST_ABSORPTION_WAIT_ADD_VIEW_ROUTE = {
    name: 'costAbsorptionWaitAddView',
    path: 'absorption/cost/wait/add',
    meta: { name: '费用待摊单新增', keepAlive: true, uid: 'costAbsorptionWaitAddView' },
    component: () =>
        import ('@/views/absorption/cost/wait/add')
}

export const COST_ABSORPTION_WAIT_DETAIL_VIEW_ROUTE = {
    name: 'costAbsorptionWaitDetailView',
    path: 'absorption/cost/wait/detail',
    meta: { name: '费用待摊单详情', keepAlive: true, uid: 'costAbsorptionWaitDetailView' },
    component: () =>
        import ('@/views/absorption/cost/wait/detail')
}

// 费用分摊单
export const COST_ABSORPTION_VIEW_ROUTE = {
    name: 'costAbsorptionView',
    path: 'absorption/cost',
    meta: { name: '费用分摊单', keepAlive: true, uid: 'costAbsorptionView' },
    component: () =>
        import ('@/views/absorption/cost')
}

export const COST_ABSORPTION_ADD_VIEW_ROUTE = {
    name: 'costAbsorptionAddView',
    path: 'absorption/cost/add',
    meta: { name: '费用分摊单新增', keepAlive: true, uid: 'costAbsorptionAddView' },
    component: () =>
        import ('@/views/absorption/cost/add')
}

export const COST_ABSORPTION_DETAIL_VIEW_ROUTE = {
    name: 'costAbsorptionDetailView',
    path: 'absorption/cost/detail',
    meta: { name: '费用分摊单详情', keepAlive: true, uid: 'costAbsorptionDetailView' },
    component: () =>
        import ('@/views/absorption/cost/detail')
}

// 采购成本
// 对公付款单
export const PAY_APPLY_ITEM_PURCHASE_VIEW_ROUTE = {
    name: 'payApplyItemPurchaseView',
    path: 'absorption/purchase/pay',
    meta: { name: '对公付款单', keepAlive: true, uid: 'payApplyItemPurchaseView' },
    component: () =>
        import ('@/views/absorption/purchase/pay')
}

// 期间成本分摊单
export const PURCHASE_ABSORPTION_VIEW_ROUTE = {
    name: 'purchaseAbsorptionView',
    path: 'absorption/purchase',
    meta: { name: '期间成本分摊单', keepAlive: true, uid: 'purchaseAbsorptionView' },
    component: () =>
        import ('@/views/absorption/purchase')
}

export const PURCHASE_ABSORPTION_ADD_VIEW_ROUTE = {
    name: 'purchaseAbsorptionAddView',
    path: 'absorption/purchase/add',
    meta: { name: '期间成本分摊单新增', keepAlive: true, uid: 'purchaseAbsorptionAddView' },
    component: () =>
        import ('@/views/absorption/purchase/add')
}

export const PURCHASE_ABSORPTION_DETAIL_VIEW_ROUTE = {
    name: 'purchaseAbsorptionDetailView',
    path: 'absorption/purchase/detail',
    meta: { name: '期间成本分摊单详情', keepAlive: true, uid: 'purchaseAbsorptionDetailView' },
    component: () =>
        import ('@/views/absorption/purchase/detail')
}