// 报销
// 费用报销
export const COST_REIMBURSE_VIEW_ROUTE = {
    name: 'costReimburseView',
    path: 'daily/reimburse/cost',
    meta: { name: '费用报销单', keepAlive: true, uid: 'costReimburseView' },
    component: () =>
        import ('@/views/daily/reimburse/cost')
}

// 办公费
export const COST_REIMBURSE_OFFICE_DETAIL_VIEW_ROUTE = {
    name: 'costReimburseOfficeDetailView',
    path: 'daily/reimburse/cost/office/detail/:id',
    meta: { name: '办公费报销单查看', keepAlive: true, uid: 'costReimburseOfficeDetailView' },
    component: () =>
        import ('@/views/daily/reimburse/cost/office/detail')
}

// 招待费
export const COST_REIMBURSE_SERVE_DETAIL_VIEW_ROUTE = {
    name: 'costReimburseServeDetailView',
    path: 'daily/reimburse/cost/serve/detail/:id',
    meta: { name: '招待费报销单查看', keepAlive: true, uid: 'costReimburseServeDetailView' },
    component: () =>
        import ('@/views/daily/reimburse/cost/serve/detail')
}

// 交通费
export const COST_REIMBURSE_TRANSPORT_DETAIL_VIEW_ROUTE = {
    name: 'costReimburseTransportDetailView',
    path: 'daily/reimburse/cost/transport/detail/:id',
    meta: { name: '交通费报销单查看', keepAlive: true, uid: 'costReimburseTransportDetailView' },
    component: () =>
        import ('@/views/daily/reimburse/cost/transport/detail')
}

// 住宿费
export const COST_REIMBURSE_HOTEL_DETAIL_VIEW_ROUTE = {
    name: 'costReimburseHotelDetailView',
    path: 'daily/reimburse/cost/hotel/detail/:id',
    meta: { name: '住宿费报销单查看', keepAlive: true, uid: 'costReimburseHotelDetailView' },
    component: () =>
        import ('@/views/daily/reimburse/cost/hotel/detail')
}

// 快递物流费
export const COST_REIMBURSE_LOGISTICS_DETAIL_VIEW_ROUTE = {
    name: 'costReimburseLogisticsDetailView',
    path: 'daily/reimburse/cost/logistics/detail/:id',
    meta: { name: '快递物流费报销单查看', keepAlive: true, uid: 'costReimburseLogisticsDetailView' },
    component: () =>
        import ('@/views/daily/reimburse/cost/logistics/detail')
}

// 通讯费
export const COST_REIMBURSE_COMMUNICATION_DETAIL_VIEW_ROUTE = {
    name: 'costReimburseCommunicationDetailView',
    path: 'daily/reimburse/cost/communication/detail/:id',
    meta: { name: '通讯费报销单查看', keepAlive: true, uid: 'costReimburseCommunicationDetailView' },
    component: () =>
        import ('@/views/daily/reimburse/cost/communication/detail')
}

// 燃油费
export const COST_REIMBURSE_OIL_DETAIL_VIEW_ROUTE = {
    name: 'costReimburseOilDetailView',
    path: 'daily/reimburse/cost/oil/detail/:id',
    meta: { name: '燃油费报销单查看', keepAlive: true, uid: 'costReimburseOilDetailView' },
    component: () =>
        import ('@/views/daily/reimburse/cost/oil/detail')
}

// 路桥费
export const COST_REIMBURSE_WAY_DETAIL_VIEW_ROUTE = {
    name: 'costReimburseWayDetailView',
    path: 'daily/reimburse/cost/way/detail/:id',
    meta: { name: '路桥费报销单查看', keepAlive: true, uid: 'costReimburseWayDetailView' },
    component: () =>
        import ('@/views/daily/reimburse/cost/way/detail')
}

// 车辆维修保养费
export const COST_REIMBURSE_MAINTENANCE_DETAIL_VIEW_ROUTE = {
    name: 'costReimburseMaintenanceDetailView',
    path: 'daily/reimburse/cost/maintenance/detail/:id',
    meta: { name: '车辆维修保养费报销单查看', keepAlive: true, uid: 'costReimburseMaintenanceDetailView' },
    component: () =>
        import ('@/views/daily/reimburse/cost/maintenance/detail')
}

// 其他费用
export const COST_REIMBURSE_OTHER_DETAIL_VIEW_ROUTE = {
    name: 'costReimburseOtherDetailView',
    path: 'daily/reimburse/cost/other/detail/:id',
    meta: { name: '其他费用报销单查看', keepAlive: true, uid: 'costReimburseOtherDetailView' },
    component: () =>
        import ('@/views/daily/reimburse/cost/other/detail')
}

// 员工借款单
export const EMPLOYEE_LOAN_VIEW_ROUTE = {
    name: 'employeeLoanView',
    path: 'daily/loan/employee',
    meta: { name: '员工借款单', keepAlive: true, uid: 'employeeLoanView' },
    component: () =>
        import ('@/views/daily/loan/employee')
}

export const EMPLOYEE_LOAN_DETAIL_VIEW_ROUTE = {
    name: 'employeeLoanDetailView',
    path: 'daily/loan/employee/detail/:id',
    meta: { name: '员工借款单查看', keepAlive: true, uid: 'employeeLoanDetailView' },
    component: () =>
        import ('@/views/daily/loan/employee/detail')
}

// 员工借款还款单
export const EMPLOYEE_LOAN_REPAY_VIEW_ROUTE = {
    name: 'employeeLoanRepayView',
    path: 'daily/loan/employee/repay',
    meta: { name: '员工借款还款单', keepAlive: true, uid: 'employeeLoanRepayView' },
    component: () =>
        import ('@/views/daily/loan/employee/repay')
}

export const EMPLOYEE_LOAN_REPAY_DETAIL_VIEW_ROUTE = {
    name: 'employeeLoanRepayDetailView',
    path: 'daily/loan/employee/repay/detail/:id',
    meta: { name: '员工借款还款单查看', keepAlive: true, uid: 'employeeLoanRepayDetailView' },
    component: () =>
        import ('@/views/daily/loan/employee/repay/detail')
}

// 对公付款
export const PAY_APPLY_VIEW_ROUTE = {
    name: 'payApplyView',
    path: 'daily/pay/apply',
    meta: { name: '对公付款单', keepAlive: true, uid: 'payApplyView' },
    component: () =>
        import ('@/views/daily/pay/apply')
}

// 运输费付款单
export const PAY_APPLY_TRANSPORT_DETAIL_VIEW_ROUTE = {
    name: 'payApplyTransportDetailView',
    path: 'daily/pay/apply/transport/detail/:id',
    meta: { name: '交通运输费付款单查看', keepAlive: true, uid: 'payApplyTransportDetailView' },
    component: () =>
        import ('@/views/daily/pay/apply/transport/detail')
}

// 广告费付款单
export const PAY_APPLY_AD_DETAIL_VIEW_ROUTE = {
    name: 'payApplyAdDetailView',
    path: 'daily/pay/apply/transport/detail/:id',
    meta: { name: '广告费付款单查看', keepAlive: true, uid: 'payApplyAdDetailView' },
    component: () =>
        import ('@/views/daily/pay/apply/ad/detail')
}