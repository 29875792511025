// 采购单
export const PURCHASE_VIEW_ROUTE = {
    name: 'purchaseView',
    path: 'purchase',
    meta: { name: '采购单', keepAlive: true, uid: 'purchaseView' },
    component: () =>
        import ('@/views/purchase')
}

export const PURCHASE_DETAIL_VIEW_ROUTE = {
    name: 'purchaseDetailView',
    path: 'purchase/detail/:id',
    meta: { name: '采购单查看', keepAlive: true, uid: 'purchaseDetailView' },
    component: () =>
        import ('@/views/purchase/detail')
}

// 发票单
export const PURCHASE_INVOICE_VIEW_ROUTE = {
    name: 'purchaseInvoiceView',
    path: 'purchase/invoice',
    meta: { name: '采购发票单', keepAlive: true, uid: 'purchaseInvoiceView' },
    component: () =>
        import ('@/views/purchase/invoice')
}

export const PURCHASE_INVOICE_DETAIL_VIEW_ROUTE = {
    name: 'purchaseInvoiceDetailView',
    path: 'purchase/invoice/detail/:id',
    meta: { name: '采购发票单查看', keepAlive: true, uid: 'purchaseInvoiceDetailView' },
    component: () =>
        import ('@/views/purchase/invoice/detail')
}